import * as React from "react";
import "../styles/styles.css";
import { Link } from "gatsby";
import MainLayout from "../components/MainLayout";

import Accordion from "../components/Accordion";

import SEO from "../components/Seo";
import { META_TAGS } from "../utils/metaTags";

const FAQ_LIST_1 = [
  {
    title: `How much does Answering Real Estate cost?`,
    content: `It depends on the size of your business. However, we customize all of our pricing plans to fit your unique business and offer you a flat monthly rate with overage protection. This way you don’t have too few minutes causing you to incur costly overage charges, and you don’t have too many minutes resulting in a higher bill with wasted minutes at the end of each month.`,
  },

  {
    title: `How do I get setup with Answering Real Estate?`,
    content: `Either sign up on our website or give us a call at 631-450-1000 to speak with a Customer Success Expert. He/She will help you sign up and go over all of your customizations, and will even handle the forwarding of your calls over to us if you request.`,
  },
];
const FAQ_LIST_2 = [
  {
    title: `Does Answering Real Estate charge per call or per minute?`,
    content: `Answering Real Estate charges per minute, and we don’t round up in order to increase your usage. You only pay for what you use.`,
  },
  {
    title: `Does Answering Real Estate charge me for telemarketing calls?`,
    content: `No! Telemarketing calls are no-charge calls in our system. You will never be charged for nuisance calls.`,
  },
  {
    title: `Can I get custom pricing for my business?`,
    content: `Yes. Either fill out a form for a custom quote or give us a call at 631-450-1000 and speak to a representative about custom pricing.`,
  },
];

const IndexPage = () => {
  return (
    <MainLayout>
      <div className="inner_main_wrapper">
        <div className="inner_container">
          <div className="container">
            <div className="text-center pagehead">
              <h2>
                <strong>We’ve got you covered</strong>
              </h2>
              <h5 className="para-15 subheader">
                Every business has different needs. That’s why each of our price
                models can be scaled to fit different businesses.
              </h5>
            </div>
            <div className="text-center">
              <Link
                className="btn-lg btn-border-blue"
                id="tryfree"
                href="/signup"
              >
                Try free for 400 minutes
              </Link>
              <Link
                className="btn-lg btn-green"
                id="tryfree"
                href="/get-a-quote"
              >
                Get a custom quote
              </Link>
            </div>
            <div id="pricing-plans" className="section group">
              <div className="pricing-box">
                <h2 className="lightblue-txt">Basic</h2>
                <p className="pricing-txt">
                  Perfect for the small business looking to better serve their
                  customers.
                </p>
                <p className="pricing-price">
                  $ <strong>360</strong>/month
                </p>
                <Link href="/signup">
                  <button className="pricing-btn">Get Started</button>
                </Link>
              </div>
              <div className="pricing-box best">
                <h2 className="lightblue-txt">Standard</h2>
                <p className="pricing-txt">
                  Suitable for a growing business looking to support their
                  infrastructure.
                </p>
                <p className="pricing-price">
                  $ <strong>676</strong>/month
                </p>
                <Link href="/signup">
                  <button className="pricing-btn">Get Started</button>
                </Link>
              </div>
              <div className="pricing-box">
                <h2 className="green-txt">Plus</h2>
                <p className="pricing-txt">
                  Designed for larger businesses looking to decrease costs.
                </p>
                <p className="pricing-price">
                  $ <strong>1,335</strong>/month
                </p>
                <Link href="/signup">
                  <button className="pricing-btn">Get Started</button>
                </Link>
              </div>
            </div>
          </div>
          <div className="container package-bg">
            <p id="note" className="para-14 center">
              *each package is based off our averages to show what you can
              expect to pay
            </p>
            <div id="package">
              <div class="col span_4_of_12 center">
                <ul class="pricing-features">
                  <li>
                    <strong>100</strong> Minutes Per Month
                  </li>

                  <li>
                    <strong>Unlimited</strong> Forwarding
                    <div class="tooltip">
                      <img
                        decoding="async"
                        src="https://answeringrealestate.com/wp-content/uploads/2017/07/question.png"
                      />
                      <span class="tooltiptext">
                        Forward us as many numbers as you need. Simply let us
                        know which numbers you are going to be forwarding.
                      </span>
                    </div>
                  </li>
                  <li>
                    <strong>Bilingual</strong> Receptionists
                  </li>
                  <li>
                    <strong>Unlimited</strong> Talk Time
                    <div class="tooltip">
                      <img
                        decoding="async"
                        src="https://answeringrealestate.com/wp-content/uploads/2017/07/question.png"
                      />
                      <span class="tooltiptext">
                        After the call is transferred, all talk time after that
                        is not included in your monthly minute rate.
                      </span>
                    </div>
                  </li>
                  <li>
                    <strong>Free</strong> Call Patching
                  </li>
                  <li>
                    <strong>Text/Email</strong> Message Delivery
                  </li>
                </ul>
              </div>
              <div class="col span_4_of_12">
                <ul class="pricing-features center">
                  <li>
                    <strong>200</strong> Minutes Per Month
                  </li>

                  <li>
                    <strong>Unlimited</strong> Forwarding
                    <div class="tooltip">
                      <img
                        decoding="async"
                        src="https://answeringrealestate.com/wp-content/uploads/2017/07/question.png"
                      />
                      <span class="tooltiptext">
                        Forward us as many numbers as you need. Simply let us
                        know which numbers you are going to be forwarding.
                      </span>
                    </div>
                  </li>
                  <li>
                    <strong>Bilingual</strong> Receptionists
                  </li>
                  <li>
                    <strong>Unlimited</strong> Talk Time
                    <div class="tooltip">
                      <img
                        decoding="async"
                        src="https://answeringrealestate.com/wp-content/uploads/2017/07/question.png"
                      />
                      <span class="tooltiptext">
                        After the call is transferred, all talk time after that
                        is not included in your monthly minute rate.
                      </span>
                    </div>
                  </li>
                  <li>
                    <strong>Free</strong> Call Patching
                  </li>
                  <li>
                    <strong>Text/Email</strong> Message Delivery
                  </li>
                </ul>
              </div>
              <div class="col span_4_of_12">
                <ul class="pricing-features center">
                  <li>
                    <strong>500+</strong> Minutes Per Month
                  </li>

                  <li>
                    <strong>Unlimited</strong> Forwarding
                    <div class="tooltip">
                      <img
                        decoding="async"
                        src="https://answeringrealestate.com/wp-content/uploads/2017/07/question.png"
                      />
                      <span class="tooltiptext">
                        Forward us as many numbers as you need. Simply let us
                        know which numbers you are going to be forwarding.
                      </span>
                    </div>
                  </li>
                  <li>
                    <strong>Bilingual</strong> Receptionists
                  </li>
                  <li>
                    <strong>Unlimited</strong> Talk Time
                    <div class="tooltip">
                      <img
                        decoding="async"
                        src="https://answeringrealestate.com/wp-content/uploads/2017/07/question.png"
                      />
                      <span class="tooltiptext">
                        After the call is transferred, all talk time after that
                        is not included in your monthly minute rate.
                      </span>
                    </div>
                  </li>
                  <li>
                    <strong>Free</strong> Call Patching
                  </li>
                  <li>
                    <strong>Text/Email</strong> Message Delivery
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="container">
            <section id="faq">
              <div className="center">
                <h2>Frequently asked questions</h2>
              </div>
              <div className="group">
                <div className="col span_6_of_12">
                  {FAQ_LIST_1.map((faq) => (
                    <Accordion className={"col-sm-12"} {...faq} />
                  ))}
                </div>
                <div className="col span_6_of_12">
                  {FAQ_LIST_2.map((faq) => (
                    <Accordion className={"col-sm-12"} {...faq} />
                  ))}
                </div>
              </div>
            </section>
          </div>
          <hr class="span_6_of_12" style={{ marginTop: 80 }}></hr>
          <div className="container">
            <section id="contact">
              <div class="center">
                <h2>Not ready just yet?</h2>
                <div>
                  <Link href="/tour">
                    <button
                      class="btn-lg btn-border-blue"
                      style={{
                        paddingLeft: 80,
                        paddingRight: 80,
                      }}
                    >
                      Learn more
                    </button>
                  </Link>
                  <p class="or">OR</p>
                  <Link href="/signup">
                    <button class="btn-lg btn-green">
                      Try free for 400 minutes
                    </button>
                  </Link>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </MainLayout>
  );
};

export default IndexPage;

export const Head = () => <SEO {...META_TAGS["ANSWERING_SERVICE_PRICING"]} />;
